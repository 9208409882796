@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  width: 100%;
  height: 100vh;
}
#canvas {
  width: 100%;
  height: calc(100% - 0px);;
  padding-top:60px;
}
.react-flow__attribution {
  display: none;
}

.response-tabs  div {
  justify-content: space-between;
}